import { createContext, Dispatch, ReactNode, SetStateAction, useState } from "react"

type ModalProps = {
    title:string,
    text:string,
}

type ModalContextProps = {
    modal: ModalProps | null
    setModal:Dispatch<SetStateAction<ModalProps | null>>
}
type MyProviderProps = {
    children: ReactNode;
  };
export const ModalContext = createContext<ModalContextProps>({} as ModalContextProps)

export default ():React.FC<MyProviderProps> => {
    const [modal, setModal] = useState<ModalProps | null>(null)
    return ({children}) => (
        <ModalContext.Provider value={{modal,setModal}}>
            {children}
        </ModalContext.Provider>
    )
}