import RatingInfo from "types/rating-info";
import tg from "utils/tg";
const tg_id = tg.initDataUnsafe.user?.id;
export default async (type:'global' | 'personal'):Promise< null | RatingInfo> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += type === 'global' ? "rating_global" : 'rating_personal';
    API_URL.searchParams.set("tg_id", String(tg_id));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    return null
  }
};
