import React, { useContext, useState } from 'react'
import PageWrapper from 'UI/PageWrapper'
import './store.css'

import ruby from 'images/ruby.png'
import ticket from 'images/ticket.svg'
import coin from 'images/coin.png'
import store1 from 'images/store-categories/store-1.png'
import store2 from 'images/store-categories/store-2.png'
import air1 from 'images/store-categories/air-1.png'
import air2 from 'images/store-categories/air-2.png'
import frormatNumber from 'utils/frormat-number'
import Button from 'UI/Button'
import AppContext from 'utils/app-context'
type Props = {}

const Store = (props: Props) => {
  const [currentCategory, setCurrentCategory] = useState<'store' | 'air'>('store')
  const {user} = useContext(AppContext)
  return (
    <PageWrapper>
      <ul className="user-balance-list">
        <li className="user-balance-list-item glass-bg">
          <img src={ruby} alt="" width={24} height={24} />
          <span>{frormatNumber(user?.diamonds || 0)}</span>
        </li>
       <li className="user-balance-list-item glass-bg">
          <img src={ticket} alt="" height={24}/>
          <span>{frormatNumber(user?.tickets || 0)}</span>
        </li>
       <li className="user-balance-list-item glass-bg">
          <img src={coin} alt="" width={24} height={24}/>
          <span>{frormatNumber(user?.balance || 0)}</span>
        </li>
      </ul>
      <ul className="store-categories">
        <li className='store-categories-item'>
          <Button text='Store' active={currentCategory === 'store'} onClick={() => setCurrentCategory('store')}/>
        </li>
        <li className='store-categories-item'>
          <Button text='Airdrop' active={currentCategory === 'air'} onClick={() => setCurrentCategory('air')}/>
        </li>
      </ul>
      <ul className='store-categories-list'>
        {
          currentCategory === 'store' && <>
          <li className="store-categories-list-item">
            <img className='store-categories-list-image' src={store1} alt="" />
            <h2 className='store-categories-list-title'>wheel <br /> of fortune</h2>
            <Button text='Soon' active disabled/>
          </li>
          <li className="store-categories-list-item">
            <img className='store-categories-list-image' src={store2} alt="" />
            <h2 className='store-categories-list-title'>Boosters</h2>
            <Button text='Soon' active disabled/>
          </li>
          </>
        }
        {
          currentCategory === 'air' && <>
          <li className="store-categories-list-item">
            <img className='store-categories-list-image' src={air1} alt="" />
            <h2 className='store-categories-list-title'>MEME BOXES</h2>
            <Button text='Soon' active disabled/>
          </li>
          <li className="store-categories-list-item">
            <img className='store-categories-list-image' src={air2} alt="" />
            <h2 className='store-categories-list-title'>Blast ticket</h2>
            <Button text='Soon' active disabled/>
          </li>
          </>
        }
      </ul>
    </PageWrapper>
  )
}

export default Store