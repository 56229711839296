// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-balance-list{
    width: 100%;
    display: flex;
    gap: 8px;
}

.user-balance-list-item{
    border-radius: 4px;
    padding: 10.5px 0;
    gap: 4px;
    display: flex;
    align-items: center;
    flex: 1 1;
    justify-content: center;
    font-weight: 700;
}

.store-categories{
    margin-top: 24px;
    margin-bottom: 14px;
    display: flex;
    gap: 8px;
    
}
.store-categories li{
    flex: 1 1;
}
.store-categories li .btn{
    width: 100%;
}

.store-categories-list{
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.store-categories-list-item{
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    position: relative;
    padding: 10px;
  box-shadow: 0px 4px 4px #00000040;

}
.store-categories-list-image{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;
}
.store-categories-list-title{
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
}`, "",{"version":3,"sources":["webpack://./src/pages/Store/store.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,QAAQ;IACR,aAAa;IACb,mBAAmB;IACnB,SAAO;IACP,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,QAAQ;;AAEZ;AACA;IACI,SAAO;AACX;AACA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,WAAW;IACX,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;EACf,iCAAiC;;AAEnC;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,MAAM;IACN,OAAO;IACP,WAAW;AACf;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,yBAAyB;AAC7B","sourcesContent":[".user-balance-list{\r\n    width: 100%;\r\n    display: flex;\r\n    gap: 8px;\r\n}\r\n\r\n.user-balance-list-item{\r\n    border-radius: 4px;\r\n    padding: 10.5px 0;\r\n    gap: 4px;\r\n    display: flex;\r\n    align-items: center;\r\n    flex: 1;\r\n    justify-content: center;\r\n    font-weight: 700;\r\n}\r\n\r\n.store-categories{\r\n    margin-top: 24px;\r\n    margin-bottom: 14px;\r\n    display: flex;\r\n    gap: 8px;\r\n    \r\n}\r\n.store-categories li{\r\n    flex: 1;\r\n}\r\n.store-categories li .btn{\r\n    width: 100%;\r\n}\r\n\r\n.store-categories-list{\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 14px;\r\n}\r\n\r\n.store-categories-list-item{\r\n    border-radius: 20px;\r\n    overflow: hidden;\r\n    width: 100%;\r\n    height: 230px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    align-items: end;\r\n    position: relative;\r\n    padding: 10px;\r\n  box-shadow: 0px 4px 4px #00000040;\r\n\r\n}\r\n.store-categories-list-image{\r\n    position: absolute;\r\n    width: 100%;\r\n    top: 0;\r\n    left: 0;\r\n    z-index: -1;\r\n}\r\n.store-categories-list-title{\r\n    font-weight: 700;\r\n    font-size: 16px;\r\n    text-transform: uppercase;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
