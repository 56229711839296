import React, { useState } from "react";
import PageWrapper from "UI/PageWrapper";
import UserBalance from "UI/UserBalance";
import "./Friends.css";
import gift from "assets/images/gift.png";
import Icon from "UI/Icon";
import Button from "UI/Button";
import tg from "utils/tg";
const tg_id = tg.initDataUnsafe.user?.id;
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(tg_id)) || process.env.REACT_APP_SHARE_LINK || ''
const shareText = process.env.REACT_APP_SHARE_TEXT || '';

const Friends = () => {
  const [copy, setcopy] = useState(false)

  
  const copyShareLink = (e:React.MouseEvent) => {
    setcopy(true)
    tg.HapticFeedback.notificationOccurred('success')
    navigator.clipboard.writeText(inviteLink)
    setTimeout(() => setcopy(false),1000)
  }
  const TelegramShare = () => {
      tg.openTelegramLink(`https://t.me/share/url?url=${inviteLink}&text=${(shareText.replace(/\\n/g, '%0A'))}`)
  }

  return (
    <PageWrapper>
      <UserBalance />
      <div className="friends-page-info">
        <div>
          <img className="friends-page-gift" src={gift} alt="" />
          <h1>
            <span className="sand-text2">Get 20%</span> of your <br />
            friend’s income
          </h1>
        </div>
        <div className="invite-friend">
          <div className="invite-friend-desc">
            <h3>Invite a friend</h3>
            <span>
              <Icon icon="coin" />
              +50 for you and your friend
            </span>
          </div>
          <div className="invite-friend-buttons-wrapper">
            <Button onClick={copyShareLink}>{!copy ? 'Copy link' : 'The link is copied'}</Button>
            <Button onClick={TelegramShare} sandBtn>Share</Button>
          </div>
        </div>
      </div>
      <div className="invite-friend-counter">
        <Icon icon="navigationFriends"/>
        <p>5 friends are invited</p>
      </div>
    </PageWrapper>
  );
};

export default Friends;
