import React, { useEffect, useState } from "react";
import "./rating.css";
import PageWrapper from "UI/PageWrapper";
import Button from "UI/Button";
import frormatNumber from "utils/frormat-number";
import getRating from "api/get-rating";
import RaitingUser from "types/rating-user";
import RatingInfo from "types/rating-info";
import RatingItem from "./RatingItem";
type Props = {};

const Rating = (props: Props) => {
  const [ratingPersonal, setRatingPersonal] = useState<RatingInfo | null>(null)
  console.log("🚀 ~ Rating ~ ratingPersonal:", ratingPersonal)
  const [ratingGlobal, setRatingGlobal] = useState<RatingInfo | null>(null)
  console.log("🚀 ~ Rating ~ ratingGlobal:", ratingGlobal)
  const [currentPage, setcurrentPage] = useState<'personal' | 'global'>('personal')
  const limit = 20
  
  const displayItems = currentPage === 'global' ? ratingGlobal?.top_list : ratingPersonal?.top_list
  const currentMy = currentPage === 'global' ? ratingGlobal?.my : ratingPersonal?.my
  
  useEffect(() => {
    getRating('global').then((res) => {
    if (res) setRatingGlobal(res)
    })
    getRating('personal').then((res) => {
      if (res) setRatingPersonal(res)
      })
  }, [])

  return (
    <PageWrapper className="rating">
      <h1>Rating</h1>
      <ul className="store-categories">
        <li className="store-categories-item">
          <Button active={currentPage === 'personal'} text="Personal" onClick={() => setcurrentPage('personal')}/>
        </li>
        <li className="store-categories-item">
          <Button active={currentPage === 'global'} text="Global" onClick={() => setcurrentPage('global')}/>
        </li>
      </ul>
      <ul className="friends-list rating-list">
        {displayItems && displayItems.map((item => {
          const isMy = item.number === currentMy?.number
          return <RatingItem item={item} my={isMy}/>
        }))}
        {(currentMy && currentMy?.number > 100) && <RatingItem item={currentMy} my/>}
      </ul>
    </PageWrapper>
  );
};

export default Rating;
