import React, { ButtonHTMLAttributes } from "react";
import lock from 'images/icons/lock.svg'
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  active?:boolean
}

const Button = (props: Props) => {
  return (
    <button {...props} className={`btn ${props.active ? 'active' : 'glass-bg'} ${props.className}`}>
      {props.children ? props.children : <p className={`${props.active && 'btn-text'}`}>{props.text}</p>}
      {props.disabled && <img src={lock} width={20} height={20}/>}
    </button>
  );
};

export default Button;
