import { motion } from 'framer-motion';
import React from 'react'
import Button from 'UI/Button';
import Icon from 'UI/Icon';
import pearls from '../test-pearls';

type Props = {
    pearl: (typeof pearls)[0];
    onClick: (p: (typeof pearls)[0]) => void;
  }

const PearlCard = ({
    pearl,
    onClick,
  }:Props ) => {
    const { image, perTap, title, revenue } = pearl;
    return (
      <li className="boosters-list-item tapping-goods-list-item">
        <motion.img layout src={image} alt="" layoutId={image} />
        <div className="boosters-list-item-desc tapping-good-desc">
          <h4>{title}</h4>
          <div className="per-tap">
            <Icon icon="coin" />
            <p>
              +{perTap}t<span>/tap</span>
            </p>
          </div>
          <Button
          layoutId={title}
            className={`boosters-list-item-btn ${revenue ?? "active"}`}
            onClick={() => onClick(pearl)}
          >
            {revenue ? (
              <span className="sand-text2">{revenue} tokens</span>
            ) : (
              "Look"
            )}
          </Button>
        </div>
      </li>
    );
  };
export default PearlCard