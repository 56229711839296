const pearls = [
  {
    title: "Evening look",
    perTap: 1,
    image: "img/pearls/1.png",
  },
  {
    title: "Tasya is ready",
    perTap: 1,
    image: "img/pearls/2.png",
  },
  {
    title: "Take on me",
    perTap: 1,
    image: "img/pearls/3.png",
  },
  {
    title: "Take me on",
    perTap: 1,
    image: "img/pearls/4.png",
    revenue: 100,
  },
];
export default pearls;
