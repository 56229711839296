import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import routes from 'routes'

type Props = {}

const NavigationBar = (props: Props) => {
    const location = useLocation()
    const navigate = useNavigate()
    
  return (
    <nav className='nav-bar-container'>
        <ul className='nav-bar-list'>
            {routes.map(({navBarIcon,title,route:{path}}) => {
                const handleClick = () => navigate(path || '')
                const isActiveRoute = location.pathname === path
                return (
                    <li className={`nav-bar-list-item ${isActiveRoute && 'active'}`} key={path} onClick={handleClick}>
                        <img src={navBarIcon}  width={30} alt="" />
                        <span>{title}</span>
                    </li>
                )
            })}
        </ul>
    </nav>
  )
}

export default NavigationBar