// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rating .store-categories{
    margin-top: 10px;
}
.rating-position {
  border-radius: 50px;
  padding: 3.5px 14px;
  font-size: 12px;
}

.rating-list-item:nth-child(1) .rating-position {
  background-color: #fed759;
}

.rating-list-item:nth-child(2) .rating-position {
  background-color: #d39800;
}
.rating-list-item:nth-child(3) .rating-position {
  background-color: #9d9d9d;
}

.rating-list-item:nth-child(n+4) .rating-position {
        background: rgb(1,219,114);
        background: linear-gradient(90deg, rgba(1,219,114,0.4009978991596639) 0%, rgba(1,219,114,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#01db72",endColorstr="#01db72",GradientType=1);
  }

.rating-stats-block {
  display: flex;
  flex-direction: column;
  padding: 2px 0;
}
.rating-stats {
    display: flex;
    gap: 2px;
    align-items: center;
}

.influencers-list{
  margin-top: 15px;
  padding-bottom: 100px;
}
.my-rating{
  position: sticky;
  bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/Rating/rating.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;AACA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,yBAAyB;AAC3B;;AAEA;QACQ,0BAA0B;QAC1B,iGAAiG;QACjG,gHAAgH;EACtH;;AAEF;EACE,aAAa;EACb,sBAAsB;EACtB,cAAc;AAChB;AACA;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;AACvB;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;AACA;EACE,gBAAgB;EAChB,SAAS;AACX","sourcesContent":[".rating .store-categories{\r\n    margin-top: 10px;\r\n}\r\n.rating-position {\r\n  border-radius: 50px;\r\n  padding: 3.5px 14px;\r\n  font-size: 12px;\r\n}\r\n\r\n.rating-list-item:nth-child(1) .rating-position {\r\n  background-color: #fed759;\r\n}\r\n\r\n.rating-list-item:nth-child(2) .rating-position {\r\n  background-color: #d39800;\r\n}\r\n.rating-list-item:nth-child(3) .rating-position {\r\n  background-color: #9d9d9d;\r\n}\r\n\r\n.rating-list-item:nth-child(n+4) .rating-position {\r\n        background: rgb(1,219,114);\r\n        background: linear-gradient(90deg, rgba(1,219,114,0.4009978991596639) 0%, rgba(1,219,114,0) 100%);\r\n        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=\"#01db72\",endColorstr=\"#01db72\",GradientType=1);\r\n  }\r\n\r\n.rating-stats-block {\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 2px 0;\r\n}\r\n.rating-stats {\r\n    display: flex;\r\n    gap: 2px;\r\n    align-items: center;\r\n}\r\n\r\n.influencers-list{\r\n  margin-top: 15px;\r\n  padding-bottom: 100px;\r\n}\r\n.my-rating{\r\n  position: sticky;\r\n  bottom: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
