import React, { useEffect, useState } from "react";
import PageWrapper from "UI/PageWrapper";
import UserBalance from "UI/UserBalance";
import "./Pearls.css";
import Icon from "UI/Icon";
import Button from "UI/Button";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import { bgVariants, opacityVariants } from "utils/modal-animation";
import PearlOverview from "./components/PearlOverview";
import PearlCard from "./components/PearlCard";
import tg from "utils/tg";
import pearls from "./test-pearls";

const Pearls = () => {
  const [OpenedPearl, setOpenedPearl] = useState<(typeof pearls)[0] | null>(
    null
  );

  return (
    <>
      <PageWrapper className="pearls-page">
        <UserBalance showEnergy />
        <ul className="boosters-list tapping-goods-list pearls-list">
          {pearls.map((p) => {
            if (p.revenue) return null;
            return <PearlCard pearl={p} onClick={() => setOpenedPearl(p)} />;
          })}
        </ul>
        <div className="boosters-list-wrapper">
          <div className="divider">
            <hr /> <h3>Unlocked</h3> <hr />
          </div>
        </div>
        <ul className="boosters-list tapping-goods-list pearls-list">
          {pearls.map((p) => {
            if (!p.revenue) return null;
            return <PearlCard pearl={p} onClick={() => setOpenedPearl(p)} />;
          })}
        </ul>
      </PageWrapper>
      <PearlOverview OpenedPearl={OpenedPearl} close={() => setOpenedPearl(null)}/>
    </>
  );
};



export default Pearls;
