import React, { useContext } from "react";
import close from "images/icons/close.svg";
import { ModalContext } from "hooks/use-modal";
type Props = {};

const Modal = (props: Props) => {
  const { modal, setModal } = useContext(ModalContext);
  if (!modal) return null;
  return (
    <div className="modal-bg">
      <div className="modal glass-bg">
        <button className="close-modal" onClick={() => setModal(null)}>
          <img src={close} alt="" />
        </button>

        <h3 className="modal-title">{modal.title}</h3>
        <p className="modal-text">
          {modal.text.split('\n').map((line, index) => (
            line.trim() === '' ? <br key={index} /> : <p key={index}>{line}</p>
          ))}</p>
      </div>
    </div>
  );
};

export default Modal;
