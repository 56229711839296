import React from 'react'
import { Toplist } from 'types/rating-info'
import frormatNumber from 'utils/frormat-number'
import coin from "images/coin.png";
import greenStar from "images/green-star.png";

type Props = {
  item:Toplist
  my:boolean
}

const RatingItem = ({item:{name,number,photo,coins,points,referrals_count},my}: Props) => {
  return (
    <li key={number} className={`friends-list-item glass-bg rating-list-item ${my ? 'green-bg my-rating' : ''}`}>
    <div className="friends-list-item-info">
      <span className="rating-position">{number}</span>
      <img
        className="friends-list-item-image"
        src={photo}
      />
      <div className="rating-stats-block">
        <p>{name}</p>
        <div className="rating-stats">
          <img src={greenStar} height={24} width={24} alt="" />
          <span>{frormatNumber(points || 0)}</span>
        </div>
      </div>
    </div>
    <div className="friends-list-item-reward">
      <img src={coin} alt="coin-image " width={24} height={24} />
      <span>{frormatNumber(coins || 0)}</span>
    </div>
  </li>
  )
}

export default RatingItem