// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pearls-page {
  background-color: #0e111a;
}
.pearls-list .boosters-list-item-btn {
  margin-top: 4px;
}
.pearls-page .boosters-list-wrapper {
  margin: 15px 0 -15px;
}
.modal-bg.pearl-images {
  flex-direction: column;
  gap: 20px;
}
.pearl-images-container {
  flex: 1 1;
  overflow: auto;
}
.pearl-images-buttons {
  display: flex;
  width: 100%;
  gap: 6px;
}
.pearl-images-list{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 7px;
}
.pearl-image img{
  border-radius: 6px;
  max-width: 100%;
}
.pearl-images-buttons button {
  border-radius: 6px;
  height: 46px;
}
.pearl-images-buttons .back {
  background: #32384d;
  width: 46px;
}
.pearl-images-buttons .more {
  flex: 1 1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Pearls/Pearls.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;AACA;EACE,eAAe;AACjB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,sBAAsB;EACtB,SAAS;AACX;AACA;EACE,SAAO;EACP,cAAc;AAChB;AACA;EACE,aAAa;EACb,WAAW;EACX,QAAQ;AACV;AACA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,QAAQ;AACV;AACA;EACE,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,SAAO;AACT","sourcesContent":[".pearls-page {\r\n  background-color: #0e111a;\r\n}\r\n.pearls-list .boosters-list-item-btn {\r\n  margin-top: 4px;\r\n}\r\n.pearls-page .boosters-list-wrapper {\r\n  margin: 15px 0 -15px;\r\n}\r\n.modal-bg.pearl-images {\r\n  flex-direction: column;\r\n  gap: 20px;\r\n}\r\n.pearl-images-container {\r\n  flex: 1;\r\n  overflow: auto;\r\n}\r\n.pearl-images-buttons {\r\n  display: flex;\r\n  width: 100%;\r\n  gap: 6px;\r\n}\r\n.pearl-images-list{\r\n  width: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: stretch;\r\n  gap: 7px;\r\n}\r\n.pearl-image img{\r\n  border-radius: 6px;\r\n  max-width: 100%;\r\n}\r\n.pearl-images-buttons button {\r\n  border-radius: 6px;\r\n  height: 46px;\r\n}\r\n.pearl-images-buttons .back {\r\n  background: #32384d;\r\n  width: 46px;\r\n}\r\n.pearl-images-buttons .more {\r\n  flex: 1;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
