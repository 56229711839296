import TasksInfo from "types/tasks-info";
import tg from "utils/tg";
const tg_id = tg.initDataUnsafe.user?.id;


export default async ():Promise< null | TasksInfo> => {
  try {
    const API_URL = new URL(process.env.REACT_APP_API_URL as string)
    API_URL.pathname += "tasks";
    API_URL.searchParams.set("tg_id", String(tg_id));

    const responce = await fetch(API_URL)
    return await responce.json()
  } catch (error) {
    return null
  }
};
