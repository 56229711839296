import Game from "pages/Game/Game";
import Rating from "pages/Rating/Rating";
import Squad from "pages/Squad/Squad";
import Store from "pages/Store/Store";
import Tasks from "pages/Tasks/Tasks";
import { RouteObject } from "react-router-dom";
import store from 'images/icons/nav-bar/store.svg'
import squad from 'images/icons/nav-bar/squad.svg'
import game from 'images/icons/nav-bar/game.svg'
import rating from 'images/icons/nav-bar/rating.svg'
import tasks from 'images/icons/nav-bar/tasks.svg'

type RouteItems = {
  route: RouteObject;
  navBarIcon?: string;
  title: string;
  comingSoon?: true;
};
const routes: RouteItems[] = [
  {
    route: {
      path: "/store",
      element: <Store />,
    },
    title: "Store",
    navBarIcon:store
  },
  {
    route: {
      path: "/squad",
      element: <Squad />,
    },
    title: "Squad",
    navBarIcon:squad
  },
  {
    route: {
      path: "/",
      element: <Game />,
    },
    title: "Game",
    navBarIcon:game
  },
  {
    route: {
      path: "/rating",
      element: <Rating />,
    },
    title: "Rating",
    navBarIcon:rating
  },
  {
    route: {
      path: "/tasks",
      element: <Tasks />,
    },
    title: "Tasks",
    navBarIcon:tasks
  },
];

export default routes;
